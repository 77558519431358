import React from "react";
import Typography from "@mui/material/Typography";

function SnaggingTermsAndConditions() {
  return (
    <div>
      <Typography sx={{ fontSize: "1.25rem", fontWeight: 500, mb: 2 }}>
        Use of The New Build Inspections Website (Section A)
      </Typography>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Modification
      </Typography>
      In these Terms and Conditions ‘we, our, us’ refers to ‘New Build
      Inspections Ltd’. Acceptance of terms By accessing the content of
      www.newbuildinspections.com (‘the Website’) you agree to be bound by the
      terms and conditions set out herein and you accept our privacy policy
      available at www.newbuildinspections.com/privacy.html. If you object to
      any of the terms and conditions set out in this agreement you should not
      use any of the products or services on the Website and leave immediately.
      You agree that you shall not use the website for illegal purposes, and
      will respect all applicable laws and regulations. You agree not to use the
      website in a way that may impair the performance, corrupt the content or
      otherwise reduce the overall functionality of the Website. You also agree
      not to compromise the security of the Website or attempt to gain access to
      secured areas or sensitive information. You agree to be fully responsible
      for any claim, expense, liability, losses, costs including legal fees
      incurred by us arising from any infringement of the terms and conditions
      set out in this agreement.
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Limitation of liability
      </Typography>
      New Build Inspections will under no circumstance be liable for indirect,
      special, or consequential damages including any loss of business, revenue,
      profits, or data in relation to your use of the Website.
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Copyright
      </Typography>
      All intellectual property of New Build Inspections Ltd such as trademarks,
      trade names, patents, registered designs and any other automatic
      intellectual property rights derived from the aesthetics or functionality
      of the Website remain the property of New Build Inspections. By using the
      Website you agree to respect the intellectual property rights of New Build
      Inspections and will refrain from copying, downloading, transmitting,
      reproducing, printing, or exploiting for commercial purpose any material
      contained within the Website.
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Disclaimers
      </Typography>
      The information is provided on the understanding that the website is not
      engaged in rendering advice and should not be wholly relied upon when
      making any related decision. The information contained with the Website is
      provided on an ?as is? basis with no warranties expressed or otherwise
      implied relating to the accuracy, fitness for purpose, compatibility or
      security of any components of the Website. We do not guarantee
      uninterrupted availability of the www.newbuildinspections.com Website and
      cannot provide any representation that using the Website will be error
      free.
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Third parties
      </Typography>
      The Website may contain hyperlinks to websites operated by other parties.
      We do not control such websites and we take no responsibility for, and
      will not incur any liability in respect of, their content. Our inclusion
      of hyperlinks to such websites does not imply any endorsement of views,
      statements or information contained in such websites.
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Severance
      </Typography>
      If any provision of this Agreement is held to be invalid or unenforceable,
      such provision shall be struck out and the remaining provisions shall
      remain in force.
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Governing law and jurisdiction
      </Typography>
      This Agreement will be governed by the laws of England and any user of the
      Website hereby agrees to be bound exclusively by the jurisdiction of
      English courts without reference to rules governing choice of laws.
      <br />
      <br />
      <Typography sx={{ fontSize: "1.25rem", fontWeight: 500, mb: 2 }}>
        Inspection Services Provided by New Build Inspections (Section B)
      </Typography>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Introduction
      </Typography>
      The NBI inspector will advise the client by means of a written report as
      to his or her experienced opinion of the visible standard of condition of
      the property at the time of inspection. (No guarantee can be given or
      offered after this date as others may have access to the property post
      inspection) Photos may be taken on site by the inspector, but will not be
      used without prior consent of the home owner
      <br />
      <br />
      Any areas not inspected for any reason will be identified within the
      report, these may be, but are not limited to areas such as TV or telecoms
      which are not available or connected at the time of connection
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Introduction
      </Typography>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Introduction
      </Typography>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Introduction
      </Typography>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Introduction
      </Typography>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Introduction
      </Typography>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Introduction
      </Typography>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Introduction
      </Typography>
      <Typography sx={{ fontSize: "1.1rem", fontWeight: 500, mt: 2, mb: 1 }}>
        Introduction
      </Typography>
    </div>
  );
}

export default SnaggingTermsAndConditions;
